<template>
	<div class="invite-index-page">

		<van-image
			fit="contain"
			:src="resourceURL + 'images/invite/logo.png'"
			class="logo"
		/>
		
		<van-form @submit="onSubmit" class="content" ref="formItem" :show-error-message="false">
			<van-row style="margin-bottom:15px;">
				<van-col span="24">
					<van-field
						clearable
						v-model="formItem.password"
						type="password"
						placeholder="请输入密码"
						:rules="[{ required: true, trigger: 'onSubmit', message: '请填写密码' }]"
						style="border-radius: 24px!important;"
					/>
				</van-col>
			</van-row>

			<van-row style="margin-bottom:25px;">
				<van-col span="24">
					<van-field
						clearable
						v-model="formItem.confirm_password"
						type="password"
						placeholder="请再次输入密码"
						:rules="[{ required: true, trigger: 'onSubmit', message: '请填写密码' }]"
						style="border-radius: 24px!important;"
					/>
				</van-col>
			</van-row>

			<van-row>
				<van-col span="24">
					<van-button round block color="#FFAC00" native-type="submit">确认</van-button>
				</van-col>
			</van-row>
	
		</van-form>

	</div>
</template>

<script>
	export default {
		name: 'invite_index',
		data() {
			return {
				formItem: {
					invite_id: "",
					password: "",
					confirm_password: "",
				},

				resourceURL: this.$axios.defaults.resourceURL,
				
			}
		},
		mounted() {
			if (this.$route.query.invite_id == "" || this.$route.query.invite_id == undefined) {
				this.$router.replace('/nologin')
			} else {
				this.formItem.invite_id = this.$route.query.invite_id
			}
		},
		methods: {
			
			onSubmit() {
				this.$axios({
					method: 'post',
					url: 'invite/password',
					data: this.formItem
				}).then ((res) => {
					if (res.success) {
						this.$router.replace('/invite/success')
					}else{
						this.$toast.fail(res.error_text)
					}
				})
			}
		},
	}
</script>